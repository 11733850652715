import { MessageIntl } from "../TranslateHelpers";

export const messagesIntl: MessageIntl = {
  en: {

    url_dashboard: "/dashboard",
    url_login: "/login",
    url_password_forgotten: "/forgotten-password",
    url_password_reset: "/reset-password",
    url_activate_account: "/activate-account",
    url_application_form: "/application-form",
    url_virtual_forlder: "/virtual-folder",
    url_documents: "/documents",
    url_application_form_base: "/application-form/",
    url_application_form_define: "/application-form/admin",
    url_application_form_bio_info: "/application-form/bio-info",
    url_application_student_questionnaire: "/application-form/student-questionnaire",
    url_application_student_letter: "/application-form/student-letter",
    url_application_parents_letter: "/application-form/parents-letter",
    url_application_photo_album: "/application-form/photo-album",
    url_application_school_transcript: "/application-form/school-transcript",
    url_application_teacher: "/application-form/teacher",
    url_application_language_teacher: "/application-form/language-teacher",
    url_application_medical_info: "/application-form/medical-info",
    url_application_immunisation: "/application-form/immunisation",
    url_application_allergy: "/application-form/allergy",
    url_application_agreement: "/application-form/agreement",
    url_application_finish: "/application-form/finish",
    url_create_application_form: "/create-application-form",

    survey_title_PERS_HABITS: "Your personality and habits",
    survey_title_ABOUT_YOU: "About you",
    survey_title_ABOUT_YOUR_FAMILY: "About your family",

    to_back_office: "Go to the back office",
    logout: "Log out",
    expired_session: "Your session has expired. Please log in again",

    dashboard: "Dashboard",
    application_form: "Application form",
    applications: "Application(s)",
    virtual_folder: "Virtual folder",
    documents: "Documents",
    program_information: "Program information",
    applications_list: "Applications",
    no_application_form_found: "Unfortunately, no application form has been found. Please contact an administrator if you think there is a problem",
    single_application_form_redirect: "You are being redirected to your application form",
    order_number: "Order number",

    website: "Website",
    mail: "Email",

    home: "Home",
    copyright: "Copyright {year} © WEP All rights reserved.",
    developed_by: "Developed and maintained by",

    bio_info: "Bio info",
    student_questionnaire: "Student's\nquestionnaire",
    student_letter: "Student's\nletter",
    parents_letter: "Parent's\nletter",
    photo_album: "Photo\nalbum",
    school_transcript: "School\ntranscript",
    teacher: "Teacher",
    language_teacher: "Language\nteacher",
    medical_info: "Medical\ninfo",
    immunisation: "Immunisation",
    allergy: "Allergy",
    agreement: "Agreement",
    finish: "Finish",
    define: "Wep Admin",
    program: "Program",
    destination_country: "Destination country",
    arrival_month: "Arrival month",
    culture: "Culture",
    comments: "Comments",
    actions: "Actions",

    application_status: "Application status",
    approved_steps: "Approved steps",
    completed_steps: "Completed steps",
    incompleted_steps: "Incompleted steps",
    alerts: "Alerts",

    login: "Login",
    welcome_back: "Welcome back! Log in to your account.",
    remember_password: "Remember me",
    forgot_password: "Forgot password?",
    i_have_an_account: "I have an account",
    sign_in: "Sign in",
    dont_have_account: "Don't have account?",
    create_account: "Create Account",
    email_address: "Email Address",
    please_proper_mail: "Please enter a proper email.",
    please_pwd: "Please enter a password.",
    please_email: "Please enter an email address.",
    please_fill_in_required: "Please fill in all the required fields",
    required_field: "Please fill this field",
    password: "Password",
    password_confirm: "Confirm password",
    already_logged_in: "Welcome back! You are already logged in, you will be redirected to your dashboard.",
    forgotten_password_email: "Simply enter your address, and you will receive an email with a link to reset your password.",
    forgotten_password_submitted: "An email has been sent to the address {email}.",
    creating_account: "Your account is being created, please wait, you will be redirected very soon!",
    creating_account_error: "There has been an error creating your account. If this happens again, please contact an administrator",
    activate_needs_token_and_email: "You need both an email and an activation token to activate your account.",
    reset_password: "Reset your password",
    update_password: "Update my password",
    create_my_password: "Create my password",
    reset_password_subtitle: "You can here reset your password.",
    reset_password_submitted: "Your password has been updated, you can now use it to",
    create_password: "Create your password",
    create_password_subtitle: "You can here create your password.",
    passwords_must_be_identical: "Passwords must be identical.",
    passwords_regex: "Password must have at least one uppercase, one lowercase, one number and one special character.",
    send: "Send",

    download: "Download",
    download_pdf: "Download PDF",
    download_pdf_without_data: "Download PDF without data",
    download_now: "Download now",
    anonymous: "Anonymous",
    empty_values: "Empty values",
    tutorial: "Tutorial",
    save: "Save",
    save_and_next: "Save and next",

    first_name: "First name",
    last_name: "Last name",
    middle_names: "Middle names",
    email: "Email address",
    invalid_address: "Enter a valid email address",
    address: "Address",
    address_info: "Address extra info",
    city: "City",
    zip_code: "Postal Code",
    country: "Country",
    country_birth: "Country of birth",
    country_residence: "Country of residence",
    still_follow_studies: "Still follow studies",
    box: "Box",
    street_number: "Street number",
    select_option: "Select an option",
    student: "Student",
    participant: "Participant",
    province_state: "Province state",
    phone: "Phone",
    mobile: "Mobile",
    city_of_birth: "City of birth",
    nationality: "Nationality",
    native_language: "Native language",
    legal_guardian: "Legal guardians",
    drop_files: "Drop files here or click to upload.",
    files_successfully_uploaded: "File(s) have been successfully uploaded",
    description: "Description",
    parents_are: "Legal guardians are",
    separated: "Separated",
    couple: "Living together",
    divorced: "Divorced",
    widower: "Widow(er)",
    single_parent: "Single parent",
    other: "Other",
    legal_custody: "Legal custody is of the",
    has_custody: "Has custody",
    type_custody: "Type of custody",
    father: "Father",
    mother: "Mother",
    both: "Both",
    is_main_contact: "Is main contact",
    foster_care: "Foster care is of the type",
    exclusive: "Exclusive",
    enhance_exclusive: "Enhanced exclusive custody (super-exclusive custody)",
    parent_one_type: "Legal guardian 1",
    parent_two_type: "Legal guardian 2",
    birthdate: "Birthdate",
    gender: "Gender",
    relationship: "Relationship",
    brother: "Brother",
    sister: "Sister",
    half_brother: "Half brother",
    half_sister: "Half sister",
    step_brother: "Step brother",
    step_sister: "Step sister",
    educationLevel: "Education level",
    none: "None",
    nursery: "Nursery",
    kindergarden: "Kindergarden",
    primary_school: "Primary school",
    secondary_school: "Secondary school",
    university_college: "University / college",
    dob: "DOB",
    age: "Age",
    occupation: "Occupation",
    livingHome: "Living at home",
    yes: "Yes",
    no: "No",
    add: "Add",
    delete: "Delete",
    subjects: "Subjects",
    hours_week: "Hours/Week",
    grade: "Grade",
    parents_live_same_address: "Is living at a different address than the participant?",
    mobile_phone: "Mobile phone number (country code + #)",
    business_phone: "Business phone number (country code + #)",
    main_contact: "Main contact",
    other_people_living_at_home: "Are there any other people living at home with you (stepfather, stepmother...)?",

    export_omgt: "Export data to OMGT",
    submit_application_form: "Submit my application form!",
    submit_application_form_to_wep: "Submit application to receiving organization",
    keep_filling_application_form: "Do not submit now",
    application_complete: "Your application is complete!",
    application_incomplete: "Your application is incomplete!",
    application_form_already_submitted: "Your application has already been submitted!",
    application_form_already_submitted_text: "You cannot submit this application again.",
    application_form_submitted_on: "Your application has been submitted on :",
    application_form_submitted: "Your application has been submitted!",
    application_form_submitted_by_pax_on: "This application has been submitted by pax on :",
    staff_will_check: "Our staff will have a look at it and get back to you soon.",
    make_sure_steps: "Please take a moment and make sure that the following steps are green in order to successfully submit your application form :",
    make_sure_steps_partner: "Please take a moment and make sure that the following steps are approved by you in order to successfully submit your application form :",
    as_staff_you_cant_submit: "As staff you can't submit",
    all_sections_complete: "You can now submit your application form, which is going to be reviewed by our staff.",
    confirm_application_modal_title: "Are you sure all your data is filled?",
    confirm_delete_file_title: "Remove file",
    confirm_application_modal_text: "You will not be able to change data after confirmation. Proceed?",
    confirm_delete_file_text: "Are you sure you want to remove {filename}?",
    cannot_be_undone: "This action cannot be undone.",
    proof_id_card: "Upload your ID card",
    proof_birth_certificate: "Upload your international (multilingual) birth certificate",
    proof_id: "Upload your ID (passport)",
    codice_fiscale: "Fiscal code",
    personality_traits: "Your personality and habits",
    update: "Update",
    update_status: "Update status",
    confirm: "Confirm",
    update_my_data_and_next: "Update and go next",
    data_successfully_updated: "Your data has been successfully updated!",
    upload_pdf_pre_filled: "Upload a PDF of the pre-filled forms",
    application_form_successfully_submitted: "Your application has been successfully submitted!",
    application_form_successfully_updated: "Application form successfully updated",
    application_form_successfully_cancelled: "Application form successfully cancelled!",
    application_form_successfully_created: "Application form successfully created!",
    file_successfully_uploaded: "File successfully uploaded",
    section_successfully_updated: "Section has been successfully updated!",
    no_file_to_upload: "There is currently no file to upload",
    file_too_large: "The file you are trying to upload is too large. The maximum size allowed is {maxSize}",
    all: "All",
    app_form_not_available: "The application form you were trying to get is not available for your account.",

    food_intollerances: "Do you have a special diet or food intollerances? (ig. vegetarian, celiac, halal) *",
    take_medications_doctors_care: "Do you take medications or are under a doctor's care for management of a heal issue that requires on going care? *",
    do_you_take_medications: "Do you take any medication? *",
    have_allergies: "Do you have allergies? *",
    afraid_pets: "Are you afraid of pets? *",
    have_pets: "Do you have pets at home?",
    indicate_personality_traits: "Please indicate your personality traits",
    hobbies_and_interests: "Your hobbies and interests",
    hobbie_name: "Hobby name",
    hobbies_already_practicing: "Please select the hobbies you are practicing",
    hobbies_interested_in: "Please select the hobbies you might be interested in",
    no_interest: "No interest",
    low: "Low",
    high: "High",
    you_can_select_more_one: "(You can select more than one)",
    other_interests: "Other interests",
    list_musical_instruments_played: "List musical instruments played",
    do_you_smoke: "Do you smoke?",
    adjust_smoking_family: "Can you adjust to a smoking family?",
    smoke_outside: "Can you adjust if the family will smoke outside only?",
    smoke_ready_to_stop: "Are you ready to stop?",

    letter_length_counter: "This letter must be between {minLength} and {maxLength} characters long. Currently : {curLength}",
    school_transcript_last_3_years: "School transcripts of last 3 accomplished years",
    by_letters: "By letters",
    by_score: "By score",
    out_of: "Out of ",
    year_level: "Year level",
    year_results: "Year results",
    year_label: "Year name",
    classes_began: "School year start date",
    classes_ended: "School year end date",

    host_country: "Host country",
    duration: "Duration",
    arrival_date: 'Departure date',
    year_departure: "Departure year",
    departure_date: "Date of departure",
    program_start_date: "Program start date",
    departure_period: "Departure period",
    program_type: "Program type",
    exchange: "Exchange",
    full_fee: "Full Fee",
    area_option: "Area Option",
    application_form_template: "Application form template",
    send_account_activation_to_student: "Send account activation email to participant",
    select_if_you_want_participant_complete_on_mywep: "Select this option if you want the participant to complete the application directly on MyWEP.",
    you_can_active_later: "You can activate this option later",
    indicates_evaluation_system_school: "Please indicate the evaluation system used in your school",
    provide_details: "Please provide details",
    date_student_completed_highschool: "Date student will complete High School",
    nb_years_secondary_school: "Number of years required in your home country to complete Secondary School",
    nb_years_school_completed_before_arrival: "Number of years of school completed (not including kindergarten) prior to arrival in the host country",
    current_classes_level: "Student's current year level",
    school_info: "School info",
    id_code: "ID Code",
    complete_name_school: "Complete name of school",
    name_school_official: "Name of school official",
    school_email: "School email address",
    position_at_school: "Position at school",
    school_official_name: "School official's name",
    signature: "Signature",
    suburb: "SUBURB",
    date: "Date",
    state: "State",
    create_teacher_user_and_notify: "Create a new user for the teacher and notify them",
    teacher_user_created_and_notified_on: "A user has been created for the teacher on",

    character: "Character",
    excellent: "Excellent",
    good: "Good",
    average: "Average",
    not_know: "Not known",

    school_name_address: "School name and address",
    certify_report_is_true: "I certify that this report is true to the best of my knowledge",
    teacher_name: "Teacher name",
    teacher_position: "Teacher position in school",
    subjects_taught: "Subject(s) taught",
    teacher_email: "Teacher's email address",
    teacher_gdr: "We will use your contact details exclusively regarding this student. If you wish to be informed about WEP news (teachers’ labs, activities, webinars, newsletter etc) please give your consent by ticking this box",
    data_never_shared: "Your datas will never be shared with third parties (see our privacy policy on {siteurl})",
    teacher_applicant_motivation_letter: "In the space below, please comment applicant's motivation attendance record and study habits.",
    teacher_applicant_motivation_letter_english: "English translation",
    would_like_receive_email_presenting_wep: "I agree to receive an email presenting WEP and informing me about school activities options",
    subscribe_to_newsletter: "I agree to receiving WEP's newsletter",
    give_consent: "WEP will use your contact details exclusively regarding this student. Could you also let us know if you provide WEP with the following consent:",
    language_teacher_recommendation: "Language teacher recommendation",
    language_teacher_info: "Language teacher info",
    native_speaker_host_country: "I am a native speaker of the host country's language and I don't need to complete this STEP.",
    not_native_speaker_host_country: "I am not a native speaker of the host country's language and I don't study at school the host country's language so I don't need to complete this STEP",
    language_proficiency: "Language Proficiency",
    teacher_info: "Teacher info",
    teacher_recommendation: "Teacher recommendation",

    allergy_condition: "Allergies to Living Conditions",
    allergy_animals: "Allergies to Animals",
    allergy_food: "Food Intolerances and other Allergies",
    agreements: "Agreements",
    upload: "Upload",
    enter_details: "Enter details",
    upload_pdf: "Upload PDF",
    validation_info: "Validation info",
    upload_filled_template: "Upload the above downloaded template filled with your info",
    access_application_form: "Access your application form",

    male: "Male",
    female: "Female",
    prefer_not_say: "Prefer not to say",
    if_other: "If 'Other' is checked",
    please_complete_in_black_ink: "Please complete this form in BLACK ink.",
    physician_name: "Physician name",
    physician_statement: "Physician info",
    height: "Height",
    weight: "Weight",
    blood_group: "Blood group",
    date_of: "Date of",
    first_dose: "1st dose given",
    second_dose: "2nd dose given",
    third_dose: "3rd dose given",
    fourth_dose: "4th dose given",
    fifth_dose: "5th dose given",
    sixth_dose: "6th dose given",
    last_dose: "last dose given",

    manage: "Manage",
    current_section: "Current section",
    created_at: "Created at",
    created_by: "Created by",
    updated_at: "Updated at",
    updated_by: "Updated by",
    pdf_downloaded: "PDF downloaded",
    pdf_downloaded_at: "PDF last downloaded at",
    pdf_uploaded: "PDF uploaded",
    pdf_uploaded_at: "PDF last uploaded at",
    comment: "Comment",
    status: "Status",
    staff: "Staff",
    partner: "Partner",
    sending_organisation: "sending organisation",
    sync_omgt: "Synchronize OMGT status",
    sync_omgt_success: "OMGT status successfully synchronized",
    sync_omgt_error: "Error while synchronizing OMGT",
    export_omgt_success: "Data successfully exported to OMGT",
    export_omgt_error: "Error while exporting to OMGT",
    confirm_export_modal_title: "Confirm export?",
    confirm_notify_user_title: "Confirm user notification?",
    confirm_export_modal_text: "The customer & legal guardian data in OMGT will be updated with MyWEP data. Proceed?",
    confirm_notify_user_text: "Proceed?",
    cancel: "Cancel",
    application_form_account: "Application form & Account",
    status_sync_with_supplier_on : "Last status synchronization: ",
    data_exported_to_supplier_on : "Last data export: ",

    status_empty: "Empty",
    status_incomplete: "Incomplete by receiving organization",
    status_completed: "Completed by pax",
    status_approved: "Approved by receiving organization",
    status_pending: "In progress by pax",
    status_submitted: "Submitted by pax",

    incomplete_by: "Incomplete by",
    completed_by: "Completed by",
    approved_by: "Approved by",
    submitted_by: "Submitted by",
    in_progress_by: "In progress by",
    completed: "Completed",
    incomplete: "Incomplete",
    empty: "Empty",
    submitted: "Submitted",
    received: "Received",
    approved_by_wep: "Approved by receiving organization",


    form: "Form",
    is_approved: "Is approved",
    is_incomplete: "Is incomplete",

    if_yes_please_complete: "If yes, please complete",
    emergency_info: "Emergency info",
    over_25_not_live_with_parent: "Must be someone over the age of 25, who does not live in the family home and different than the legal guardian(s) mentioned above",

    relationship_to_student: "Relationship to the student",
    emergency_contact_email: "Emergency contact - Email Address",
    emergency_contact_mobile: "Emergency contact - Mobile phone number (country code + #)",
    emergency_contact_phone: "Emergency contact - Home phone number (country code + #)",
    emergency_contact_name: "Emergency contact - Name (First name & last name)",

    virtual_folder_updated: "Virtual folder updated",
    save_virtual_folder: "Save my virtual folder",
    save_notify_receiving_organization: "Save and notify receiving organization",

    go_to_application_form: "Go to application form",
    create_new_application_form: "Create new application form",
    create_new: "Create new",
    search_an_application: "Search for an application",
    select_destination: "Destination",
    select_program: "Program",
    select_duration: "Duration",
    select_year: "Year",
    select_country: "Destination country",
    search_by_firstname: "Participant first name",
    search_by_lastname: "Participant last name",
    search_by_email: "Participant email",
    search_by_departure_period: "Departure period",
    search_by_order_number: "WEP ref. Number",
    search_by_program_type: "Program type",
    search_partners: "Search Partners",
    apply_filters: "Apply filters",

    error_downloading_file: "Error while downloading the file",
    download_sections: "Download all sections (zip)",
    download_section: "Download {sectionName} section (pdf)",
    download_sections_one_pdf: "Download all sections (1 pdf)",
    download_agreements: "Download agreements (zip)",
    download_agreements_one_pdf: "Download agreements (1 pdf)",
    download_album: "Download photo album (zip)",
    download_all_files: "Download all files (zip)",
    generate_link_with_files: "Generate link (14 days) with all files (zip)",
    download_separate_template: "Download separate template (zip)",
    download_virtual_folder: "Download virtual folder (zip)",
    download_bio_info: "Download bio info files (zip)",
    of_the_physician: "of the physician",
    or: "Or",
    save_this_photo: "Save this photo",
    pdf_with_name: "With student's name",
    pdf_with_data: "With student's data",
    user_already_activated: "Your account has already been activated",
    user_activated_on: "Your account has been activated on",
    can_login: "You can login",
    here: "here",
    character_limit: "Character limit : {maxLength}",
    no_file_uploaded: "No file uploaded",
    select_file: "Select a file",
    no_file_selected: "No file selected",
    staff_status: "Staff status",
    status_with_wep: "Status with WEP",
    by_wep: "(by wep)",
    send_activation_email: "Send activation account email",
    email_sent: "Email successfully sent",
    inbound: "Inbound",
    outbound: "Outbound",
    summary_pending_application_forms: "Summary of your pending application forms",
    
    application_forms_empty_by_pax: "Application forms not yet started by participant",
    application_forms_completed_by_pax: "Application forms with all sections completed by participant",
    application_forms_incomplete_by_pax: "Application forms with at least one section in progress",

    application_forms_submitted_by_pax: "Application forms submitted by participant",
    application_forms_incomplete_by_partner: "Application forms incomplete by sending organization",
    application_forms_approved_by_partner: "Application forms approved by partner",

    application_forms_submitted_to_wep: "Application forms submitted by sending organization to Wep",
    application_forms_received_by_wep: "Application forms received by WEP",
    application_forms_incomplete_by_wep: "Application forms incomplete by WEP",
    application_forms_approved_by_wep: "Application forms approved by wep",

    summary_progress_pax: "Summary of the pax progress",
    summary_progress_partner: "Summary of the partner progress",
    summary_of_participant_applications: "Summary of participant applications",
    no_result: "No result",
    status_of_staff: "Application status by receiving organization",
    comment_of_staff: "Application status Comment",
    info_to_participant: "(information to participant)",

    no_access_to_page: "You don't have permission to view this page",

    choose_section: "Choose a section",
    active_student: "Active with student",
    active_partner: "Active with {userLastName}",
    active_wep: "Active with WEP",
    search: "Search",
    submit_app_form_after_editing: "To submit your application to your sending organisation after edition go to « finish »",
    generic_date: "It's a generic date and not an exact date",

    go_to_app_forms_list: "Go to applications list",
    go_to_app_form: "Go to application",
    receiving_organization: "receiving organization",
    new_app_created : "Your new application is created. Redirect to :",
    
    previous: "Previous",
    next: "Next",

    alert_immunization_incomplete: "Customer immunization requirements not complete.",
    center: "Center",
    upload_size_limit: "You cannot upload files larger than {maxSizeInMb} MB",
    extensions_upload: "You can only upload files with the following extensions:",

	invalid_format: "Invalid format",
    app_form_cancelled : "This application form is cancelled due to :",
    cancel_app_form: "Cancel this application form",
    cancel_app_form_account: "Cancel this application form and account",
    cancelled : "Cancelled",
    application_forms_cancelled: "Application forms cancelled",

    confirm_disable_appform_pax_users_title: "Are you sure to disable all users linked ?",
    confirm_disable_appform_pax_users_text: "Disable all users linked with this appication.",
    disable_appform_pax_users: "Disable",
    deactivate_pax_users: "Disable users",
    current_grade_average: "Grade average of the last two school years",

    partner_application: "Partner application",
    partner_section: "Partner section",
    download_template: "Download the template file",
    partner_form_not_yet_available: "The link to the partner online form is not yet available.",
    i_completed_partner_online_form: "I have completed the partner online form",
    only_image_or_pdf_upload: "You may only upload a pdf jpg png file.",
  },
};
